header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 10;
  background-color: rgba(#fff, .2);
  .inner{
    margin: 0 auto;
    max-width: 1600px;
    padding: 0 3%;
    position: relative;
    display: flex;
    height: 85px; // ヘッダ全体の高さ
    align-items: center;
  }
  .logo{
    width: 242px;
    height: 24px;
    margin: 0;
    a{
      display: block;
    }
  }
  @media screen and (max-width:1024px) {
    .inner{
      height: 60px;
      padding: 0 5% 0 2%;
    }
    .logo{
      width: auto;
      height: 60px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      img{
        max-width: 200px;
      }
    }
  }
}

.g_nav{
  margin: auto 0 0 auto;
  transition-duration: .3s;
  ul{
    display: flex;
    align-items: center;
    padding: 0;
    li{
      margin-right: 1.5em;
      line-height: 1;
      a{
        position: relative;
        @include fs(17);
        text-decoration: none;
        padding: 0 0 0 1.2em;
        color: #333;
        font-weight: 500;
      }
      &:not(:first-of-type){
        a::before{
          position: absolute;
          display: block;
          content: "/";
          width: 2em;
          height: 2em;
          font-size: 200%;
          color: #333;
          top: 0;
          right: 0;
          bottom: -0.8em;
          left: -0.2em;
          margin: auto auto auto 0;
          font-weight: 200;
        }
      }
      &:last-child{
        margin-right: 0;
        a{
          background: url(../img/icon_mail.png) no-repeat center 30% #eb2212;
          clip-path: polygon(0% 100%, 12.5% 0%, 100% 0%, 100% 100%);
          display: block;
          padding: 3em 2em 1em;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width:1025px) {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    ul{
      flex-flow: column;
      width: 100%;
      max-width: 480px;
      li{
        margin: 0;
        display: block;
        width: 100%;
        a{
          display: block;
          background-color: rgba($color_primary, .7);
          color: #fff;
          width: 100%;
          padding: 1em;
          border-bottom: #fff solid 1px;
          &::before{
            display: none;
          }
        }
        &:first-child{
          a{
            padding-left: 1em;
          }
        }
        &:last-child{
          a{
            clip-path: none;
            padding: 1em 1em 1em 3.5em;
            background: url(../img/icon_mail.png) no-repeat 5% #eb2212;
          }
        }
      }
    }
  }
}

@media screen and (min-width:1025px) {
  .home header{
    .logo a{
      position: relative;
      &::before{
        position: absolute;
        display: block;
        content: '';
        width: inherit;
        height: inherit;
        background: url(../img/logo_top.png) no-repeat center top;
        top: 2px;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
    .g_nav ul li{
      a{
        color: #fff;
        position: relative;
        &::before{
          color: #fff;
        }
      }
    }
  }
}

.sp_menu{
  display: none;
  @media screen and (max-width:1024px) {
    display: block;
    width: 38px;
    height: 28px;
    margin-left: auto;
    position: relative;
    span{
      display: block;
      background-color: $color_primary;
      width: 38px;
      height: 3px;
      position: relative;
      transition-duration: .3s;
      &::before, &::after{
        position: absolute;
        display: block;
        content: '';
        width: inherit;
        height: inherit;
        background-color: inherit;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto auto auto;
      }
      &::before{
        top: 12px;
      }
      &::after{
        top: 24px;
      }
    }
    &.active{
      span{
        transform: rotate(45deg);
        top: 15px;
        &::before, &::after{
          transform: rotate(-90deg);
          top: 0;
          left: 0;
        }
      }
    }
  }
}