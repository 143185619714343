@import "reset/_reset.scss";

$color_primary: #19437c; /* 基本カラーのカラーコードを入れてください */

@import "mixin/_base.scss";
@import "common/_common.scss";
@import "common/_footer_01.scss";
@import "common/_header_01.scss";
@import "common/_parts.scss";
@import "parts/_form_01.scss";

.top_1{
  background: url(../img/mv.jpg) no-repeat center top;
  background-size: cover;
  margin-bottom: 0;
  padding: 52% 0 0;
  position: relative;
  .mv{
    padding: 160px 0 1% 2.5%;
    max-width: 520px;
    max-height: 360px;
    display: block;
    position: absolute;
    top: 18%;
    right: 0;
    bottom: 0;
    left: 5%;
    margin: auto;
    background-color: rgba(#19437c, .95);
    img{
      position: absolute;
      top: -35%;
      right: 0;
      bottom: 0;
      left: -15%;
    }
    ul{
      max-width: 830px;
      width: 100%;
      margin: 0;
      li{
        display: inline-block;
        font-weight: 600;
        @include fs(22);
        color: #fff;
        margin: 2em 1em 1.7em 0;
        position: relative;
        min-width: 6em;
        &:last-child{
          margin-right: 0;
        }
        span{
          z-index: 2;
          position: relative;
        }
        &::before{
          position: absolute;
          display: block;
          content: '';
          width: 1.1em;
          height: 1.1em;
          background-color: #eb2212;
          top: -.4em;
          right: 0;
          bottom: 0;
          left: -.4em;
          margin: 0 auto auto 0;
          z-index: 1;
        }
      }
    }
    p{
      @include fs(28);
      color: #fff;
    }
  }
  @media screen and (max-width:1024px) {
    .mv{
      max-width: 57%;
      max-height: 67%;
      padding-top: 13%;
      img{
        width: 60%;
        top: -15%;
      }
      ul{
        li{
          font-size: 19px;
          span{

          }
        }
      }
    }
  }
  @media screen and (max-width:767px) {
    padding-top: 90vw;
    .mv{
      padding: 24vw 4vw 4vw;
      max-width: 80%;
      max-height: 60%;
      img{
        width: 70%;
        top: -13%;
        left: -5%;
      }
      ul{
        li{
          font-size: 3.2vw;
          span{

          }
        }
      }
      p{
        font-size: 4.7vw;
      }
    }
  }
}

ul.news{
  padding: 1em 0;
  margin-bottom: 1em;
  li{
    margin-bottom: 1.5em;
    a{
      padding: .2em 0;
      span{
        display: inline-block;
        width: 10em;
        color: $color_primary;
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width:767px) {
    li{
      a{
        span{
          display: block;
          margin-bottom: .5em;
        }
      }
    }
  }
}

.top_2{
  background: #fff url(../img/bg_01.jpg) no-repeat center top;
  background-size: 100% auto;
  padding: 0;
  .inner{
    &:nth-of-type(1){
      padding: 7% 0 8%;
    }
    &:nth-of-type(2){
      padding: 2% 0 10.5%;
      p{
        color: #fff;
        @include fs(24);
        font-weight: 500;
      }
      .f_box{
        max-width: 670px;
        margin: 0 auto;
      }
    }
  }
  @media screen and (max-width:767px) {
    background: url(../img/bg_01_sp.jpg) no-repeat center bottom;
    background-size: auto 38%;
    .inner{
      &:nth-of-type(1){
        padding: 7% 3% 8%;
      }
      &:nth-of-type(2){
        p{
          font-size: 3.7vw;
        }
      }
    }
    .f_box.gtr.sp_2{
      padding: 0 2%;
      .w5{
        width: 48%;
      }
    }
    .more a{
      margin: 0 auto;
    }
  }
}

.top_3{
  background: url(../img/bg_02.jpg) no-repeat right bottom;
  margin-bottom: 3%;
  padding: 8% 0 12%;
  .map_wide{
    margin-top: 50px;
  }
  .t_c{
    font-weight: 700;
    @include fs(22);
  }
  .r_rvs{
    padding: 9% 0 6%;
    p{
      color: #fff;
      @include fs(20);
    }
  }
  .box_1{
    border: #fff solid 3px;
    padding: 12% 2% 3% 6%;
    position: relative;
    margin-bottom: 40px;
    figure{
      position: absolute;
      top: 0;
      left: 0;
      text-align: left;
      img{
        margin: -3px 0 0 -3px;
      }
    }
    ul{
      position: relative;
      li{
        display: inline-block;
        width: 49%;
        padding: 0 0 0 1.5em;
        margin-bottom: 1em;
        position: relative;
        font-weight: 500;
        color: #fff;
        @include fs(18);
        &:before{
          position: absolute;
          display: block;
          content: '';
          width: .7em;
          height: .7em;
          border-radius: 100%;
          background-color: #fff;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto auto auto 0;
        }
      }
    }
  }
  .more a.arw{
    border: #fff solid 2px;
  }
  @media screen and (max-width:767px) {
    background-position: 100% bottom;
    background-size: 60% auto;
    .w5{
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
    }
    .map_wide{
      min-height: auto;
      padding-top: 56.25%;
      margin-top: 0;
    }
    .t_c{
      font-size: 4.7vw;
    }
    .inner:first-of-type{
      padding-bottom: 10%;
    }
    .r_rvs{
      padding: 9% 0 6%;
      p{
        font-size: 3.7vw;
      }
    }
    .box_1{
      border: #fff solid 2px;
      padding: 17% 2% 3% 6%;
      margin-bottom: 20px;
      figure{
        img{
          width: 40%;
          max-width: 151px;
        }
      }
      ul{
        li{
          width: auto;
          padding: 0 1em 0 1.2em;
          font-size: 4.7vw;
        }
      }
    }
    .more a.arw{
      margin: 0 auto;
    }
  }
}

.top_4{
  background: url(../img/bg_03.jpg) no-repeat left bottom;
  background-size: cover;
  margin-bottom: 0;
  padding: 6% 0 11.5%;
  p{
    color: #fff;
    @include fs(18);
    font-weight: 400;
    &.ttl{
      @include fs(32);
      font-weight: 700;
      margin-bottom: .5em;
    }
  }
  ul{
    margin-left: .5em;
    li{
      display: block;
      font-weight: 600;
      @include fs(24);
      color: #fff;
      margin: .8em 1em 1em 0;
      position: relative;
      min-width: 6.5em;
      &:last-child{
        margin-right: 0;
      }
      span{
        z-index: 2;
        position: relative;
      }
      &::before{
        position: absolute;
        display: block;
        content: '';
        width: 1.1em;
        height: 1.1em;
        background-color: #eb2212;
        top: -.4em;
        right: 0;
        bottom: 0;
        left: -.4em;
        margin: 0 auto auto 0;
        z-index: 1;
      }
    }
  }
  @media screen and (max-width:767px) {
    padding: 3% 0 15%;
    p{
      font-size: 4.2vw;
      &.ttl{
        font-size: 6.6vw;
      }
      &.more a{
        font-size: 4.7vw;
        margin: 0 auto;
      }
    }
    ul{
      margin-left: .5em;
      li{
        font-size: 4.7vw;
      }
    }
  }
}

.top_5{
  background-color: #fafafa;
  margin-bottom: 0;
  padding: 8% 0;
  .f_box{
    margin-bottom: 2em;
    .w5{
      &.l_box{
        border: $color_primary solid 9px;
        padding: 4% 0;
        text-align: center;
        p{
          @include fs(20);
          text-align: center;
          font-weight: 700;
          &:first-child{
            padding: 0 3em .3em;
            border-bottom: $color_primary solid 2px;
            display: inline-block;
            margin-bottom: .4em;
          }
          &.tel{
            @include fs(42);
            margin-bottom: 0;
          }
          &.fax{
            @include fs(26);
            line-height: 1.5em;
          }
        }
      }
      &.r_box{
        border: $color_primary solid 20px;
        display: flex;
        a{
          border: #fff solid 3px;
          background: $color_primary;
          width: 100%;
          height: 100%;
          min-height: 295px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          p{
            color: #fff;
            margin-top: .7em;
            @include fs(26);
            letter-spacing: 2px;
          }
        }
      }
    }
  }
  p{
    @include fs(18);
  }
  @media screen and (max-width:1024px) {
    .f_box{
      .w5{
        &.l_box{
          border: $color_primary solid 6px;
          p{
            &:first-child{
              font-size: 2vw;
            }
            &.tel{
              font-size: 4vw;
            }
            &.fax{
              font-size: 2.4vw;
            }
          }
        }
        &.r_box{
          border: $color_primary solid 14px;
          border-top: $color_primary solid 8px;
          a{
            min-height: auto;
            p{
              font-size: 2.8vw;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width:767px) {
    .f_box{
      .w5{
        width: 100%;
        &.l_box{
          p{
            &:first-child{
              font-size: 4.2vw;
            }
            &.tel{
              font-size: 8vw;
              img{
                height: .85em;
              }
            }
            &.fax{
              font-size: 4.7vw;
            }
          }
        }
        &.r_box{
          a{
            min-height: auto;
            padding: 8vw 0 6vw;
            border: #fff solid 2px;
            img{
              width: 20vw;
            }
            p{
              font-size: 4.7vw;
            }
          }
        }
      }
    }
  }
}

footer{
  background-color: #3a3a3a;
  .logo{
    padding: 2em 0;
  }
  .f_nav{
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    margin: 0 auto 2em;
    align-items: center;
    justify-content: center;
    ul li a{
      color: #fff;
      @include fs(18);
    }
  }
  .copyright{
    background-color: $color_primary;
    letter-spacing: 1px;
    padding: 1em 3%;
  }
  @media screen and (max-width:768px) {
    .f_box .logo{
      padding-left: 0;
    }
  }
}

.h1_box{
  position: relative;
  background: url(../img/h1_bg.jpg) no-repeat center bottom;
  background-size: 100% 100%;
  padding: 55px 0 0;
  margin-top: 85px;
  height: 182px;
  h1{
    @include fs(130);
    color: #61769b;
    font-style: italic;
    padding-left: .5em;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: right;
    span{
      font-style: normal;
      @include fs(40);
      text-align: left;
      color: $color_primary;
      background-color: #fff;
      padding: .2em;
      display: inline;
      width: 4.5em;
      height: 1.5em;
      top: 15px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto auto 0;
      z-index: 2;
    }
    &::before{
      position: absolute;
      display: block;
      content: '';
      z-index: 1;
      top: 4px;
      right: 0;
      bottom: 0;
      left: -10px;
      width: 35px;
      height: 30px;
      background-color: #eb2212;
    }
  }
  @media screen and (max-width:1024px) {
    height: 17vw;
    padding-top: 7vw;
    h1{
      font-size: 10vw;
      span{
        top: -2vw;
        left: 2vw;
      }
      &::before{
        top: -3vw;
        left: 1vw;
      }
    }
  }
  @media screen and (max-width:767px) {
    background-size: auto 100%;
    background-position: 10% top;
    padding: 8.3vw 0 2vw;
    margin-top: 60px;
    height: 18vw;
    h1{
      font-size: 10vw;
      padding: 0;
      span{
        font-size: 6vw;
        padding-left: .5em;
        width: 5.5em;
        margin-left: 2vw;
        top: -4vw;
      }
      &::before{
        top: -5.5vw;
        left: 2vw;
      }
    }
  }
}

.f_box + .f_box{
  margin-top: 5%;
}

.aboutus{
  .tbl_1 tr{
    th, td{
      border: #ccc solid 2px;
      font-weight: 500;
      padding: 1.5em;
      @include fs(18);
    }
    th{
      width: 30%;
      background-color: #f2f8ff;
      color: #333;
    }
    td{
      text-align: left;
      width: 70%;
      background-color: #fff;
      dl{
        display: flex;
        flex-flow: wrap;
        dt, dd{
          margin-bottom: .7em;
        }
        dt{
          width: 10em;
          color: $color_primary;
        }
        dd{
          width: calc(100% - 11em);
        }
      }
    }
    @media screen and (max-width:768px) {
      th, td{
        font-size: 3.7vw;
        padding: 1em .5em;
      }
    }
  }
  .tbl_1.ex tr{
    th, td{
      border-left: none;
      border-right: none;
    }
    th{
      background-color: #fff;
    }
  }
  .box_1{
    .w5{
      background-color: #f4f4f4;
      border: #fff solid 2px;
      p{
        padding: 1em 1em 1em 2.5em;
        position: relative;
        &::before{
          position: absolute;
          display: block;
          content: '';
          width: .8em;
          height: .8em;
          background-color: $color_primary;
          border-radius: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 1em;
          margin: auto auto auto 0;
        }
      }
    }
  }
  .box_2{
    display: flex;
    flex-flow: wrap;
    dt, dd{
      margin-bottom: 3px;
    }
    dt{
      background-color: $color_primary;
      color: #fff;
      font-weight: 500;
      border-bottom: $color_primary solid 3px;
      width: 3em;
      @include fs(24);
      padding: .8em 0 .5em;
      text-align: center;
    }
    dd{
      padding: .8em 1em .5em;
      font-weight: 500;
      border-bottom: $color_primary solid 3px;
      width: calc(100% - 4em);
      @include fs(20);
    }
  }
  @media screen and (max-width:768px) {
    .box_1{
      .w5{
        width: 100%;
        p{
          padding: 1em 1em 1em 2.5em;
          line-height: 1.5em;
          font-size: 4.7vw;
        }
      }
    }
    .box_2{
      dt, dd{
        line-height: 1.5em;
      }
      dt{
        border-bottom: $color_primary solid 2px;
        font-size: 5.2vw;
        text-align: center;
      }
      dd{
        border-bottom: $color_primary solid 2px;
        font-size: 4.7vw;
      }
    }
    .t_c.t_xlrg{
      font-size: 6vw;
    }
    .tbl_1 tr td{
      dl{
        dt, dd{
          width: 100%;
        }
        dt{
          margin-bottom: 5px;
        }
        dd{
          margin-bottom: 1.5em;
        }
      }
    }
  }
}

.business{
  .box_3{
    background: url(../img/bg_bss_01.png) no-repeat center top;
    background-size: 100% 100%;
    padding: 1.5% 5% 6%;
    p{
      text-align: center;
      color: #fff;
      @include fs(24);
      &.ttl{
        @include fs(36);
      }
    }
  }
  .bg{
    background: url(../img/bg_bss_02.jpg) no-repeat center top;
    background-size: cover;
    padding: 5% 0;
    margin-bottom: 2%;
    .f_box{
      .w33{
        p{
          &.ttl{
            position: relative;
            text-align: right;
            font-weight: 900;
            color: #0a2b60;
            padding-top: 1.3em;
            @include fs(36);
            margin-bottom: 0;
            span{
              position: absolute;
              @include fs(72);
              color: #fff;
              font-weight: 200;
              top: 12px;
              left: 0;
            }
          }
        }
        .txt{
          background: rgba(#fff, .7);
          display: flex;
          align-items: center;
          min-height: 160px;
          p{
            color: $color_primary;
            @include fs(22);
            line-height: 1.6em;
            padding: 1em;
            span{
              font-size: 200%;
              font-weight: 900;
            }
          }
        }
      }
    }
  }
  .f_box.gtr .w2 p{
    text-align: center;
    line-height: 1.7em;
    @include fs(16);
    &.ttl{
      background: url(../img/bg_bss_04.png) no-repeat center top;
      background-size: auto 100%;
      padding: 3.3em 1.3em 3.3em .3em;
      text-align: center;
      color: #fff;
      @include fs(24);
      line-height: 1;
      margin-bottom: .7em;
    }
  }
  .box_4 .f_box div{
    width: calc(100% / 7);
    border: #19437c solid 2px;
    border-right: none;
    padding: 3% 0;
    position: relative;
    &:after{
      display: block;
      content: '';
      position: absolute;
      width: 22px;
      height: 26px;
      background: url(../img/arw_2.png) no-repeat center top;
      top: 0;
      right: -21px;
      bottom: 0;
      left: 0;
      margin: auto 0 auto auto;
      z-index: 2;
    }
    &:last-of-type{
      border-right: #19437c solid 2px;
      &:after{
        display: none;
      }
    }
    &:nth-child(2){
      p{
        padding-top: 0;
      }
    }
    figure{
      min-height: 70px;
      display: flex;
      align-items: center;
      margin-bottom: .5em;
    }
    p{
      text-align: center;
      line-height: 1.4em;
      padding-top: .5em;
      color: $color_primary;
      @include fs(24);
    }
  }
  .dl_faq{
    padding: 3%;
    background-color: #f8f6f6;
    margin-bottom: 15px;
    dt, dd{
      line-height: 1.4em;
    }
    dt{
      @include fs(24);
      font-weight: 800;
      margin-bottom: 1.2em;
      span{
        color: $color_primary;
        width: 3em;
        display: inline-block;
      }
    }
    dd{
      @include fs(18);
      padding-left: 4.5em;
    }
  }
  @media screen and (max-width:768px) {
    .box_3{
      background: url(../img/bg_bss_01.png) no-repeat center top;
      background-size: 100% 100%;
      padding: 1% 10vw 8% 5vw;
      p{
        font-size: 4.2vw;
        &.ttl{
          font-size: 6vw;
          margin-bottom: .5em;
        }
      }
    }
    .f_box.gtr + .f_box{
      flex-flow: wrap;
      .w2{
        width: 50%;
      }
    }
    .box_4 .f_box{
      flex-flow: wrap;
      justify-content: flex-start;
      div{
        width: 48%;
        padding: 3% 0;
        border-bottom: none;
        &:nth-of-type(2n+2){
          border-right: #19437c solid 2px;
        }
        &:nth-of-type(6){
          border-bottom: #19437c solid 2px;
        }
        &:last-of-type{
          border-right: #19437c solid 2px;
          border-bottom: #19437c solid 2px;
        }
        &:nth-child(2){
          p{
            padding-top: 0;
          }
        }
        figure{
          min-height: 60px;
          img{
            width: 18vw;
          }
        }
        p{
          font-size: 4.7vw;
        }
      }
    }
    .dl_faq{
      margin-bottom: 10px;
      dt, dd{
        line-height: 1.5em;
      }
      dt{
        font-size: 5.2vw;
        span{
          padding-right: .5em;
        }
      }
      dd{
        font-size: 4.2vw;
        padding-left: 2.3em;
      }
    }
  }
}

.contact{
  .h1_box h1 span{
    width: 6.5em;
  }
  .tbl_1 tr{
    th, td{
      border: #ccc solid 2px;
      font-weight: 500;
      padding: 1.5em;
      @include fs(18);
    }
    th{
      width: 30%;
      background-color: #f2f8ff;
      color: #333;
      &:last-child{
        padding: 5% 0;
      }
    }
    td{
      text-align: left;
      width: 70%;
      background-color: #fff;
    }
  }
  input[type="text"], input[type="tel"], input[type="email"], textarea{
    padding: .7em;
    @include fs(18);
  }
  input[type="text"], input[type="tel"], input[type="email"]{
    width: 70%;
  }
  textarea{
    width: 100%;
  }
  input[type="submit"]{
    background: #eb2212;
    color: #fff;
    @include fs(18);
    max-width: 280px;
    width: 100%;
    padding: .7em 1em;
    display: inline-block;
    border: none;
    letter-spacing: 1px;
  }
  h4 + p{
    font-weight: 500;
    margin-bottom: 3em;
  }
  @media screen and (max-width:768px) {
    .h1_box h1 span{
      width: 7em;
    }
    .tbl_1 tr{
      th, td{
        font-size: 3.7vw;
        padding: 1em .5em;
      }
    }
    input[type="text"], input[type="tel"], input[type="email"], textarea{
      padding: .4em;
      font-size: 4.2vw;
      width: 100%;
    }
  }
}

.ul_news{
  border-top: #ccc solid 1px;
  padding: 0;
  li{
    @include fs(18);
    font-weight: 500;
    padding: 2em 0;
    border-bottom: #ccc solid 1px;
    margin-bottom: 0;
    span{
      @include fs(18);
      color: #1a429d;
      font-weight: 700;
      display: inline-block;
      margin-right: 2em;
    }
  }
  @media screen and (max-width:768px) {
    li{
      span{
        font-size: 4.7vw;
        display: block;
        margin-bottom: .5em;
      }
    }
  }
}

.equipment{
  h3{
    background-color: #f4f4f4;
    padding: .7em 1em .7em 2.5em;
    position: relative;
    border-width: 4px;
    @include fs(24);
    margin-bottom: 12px;
    &::before{
      position: absolute;
      display: block;
      content: '';
      width: .8em;
      height: .8em;
      background-color: $color_primary;
      border-radius: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 1em;
      margin: auto auto auto 0;
    }
  }
  .dl_eqip{
    margin-bottom: 2em;
    @include fs(18);
    display: flex;
    flex-flow: wrap;
    font-weight: 500;
    justify-content: space-between;
    dt, dd{
      margin-bottom: 8px;
      padding: 1em 1.2em;
    }
    dt{
      background-color: $color_primary;
      color: #fff;
      width: 30%;
    }
    dd{
      width: 68%;
      padding-left: 0;
    }
  }
  @media screen and (max-width:768px) {
    h3{
      font-size: 5vw;
    }
    .dl_eqip{
      dt{
        width: 100%;
        margin-bottom: 5px;
      }
      dd{
        width: 90%;
        margin-left: auto;
        padding-top: 5px;
      }
    }
  }
}