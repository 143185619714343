footer{
	text-align: center;
	background-color: $color_primary;
	line-height: 1.65em;
	color: #fff;
	width: 100%;
	margin: 0 auto;
	.f_nav{
		background-color: #fff;
		padding: 1em 0;
		border-top: $color_primary solid 2px;
		ul{
			li{
				display: inline-block;
				line-height: 1;
				a{
					padding: 0 1em;
					color: #333;
					@include fs(14);
					text-decoration: none;
				}
				&:not(:first-child){
					border-left: #333 solid 1px;
				}
			}
		}
	}
	.copyright{
		padding: 1em 0;
		color: #fff;
	}
	@media screen and (max-width:767px) {
		.f_nav{
			background-color: #fff;
			padding: 0;
			border-top: $color_primary solid 2px;
			ul{
				li{
					width: 100%;
					border-bottom: $color_primary solid 1px;
					a{
						display: block;
						padding: 1em;
					}
					&:not(:first-child){
						border-left: none;
					}
				}
			}
		}
		.copyright{
			@include fs(12);
		}
	}
}
