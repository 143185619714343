@media screen and (max-width:767px) {.pc_only{display: none !important;} .sp_only{display: block !important;}}
@media screen and (min-width:768px) {.sp_only{display: none !important;} .pc_only{display: block !important;}}

html{
	font-size: 62.5%;
}

body {
	line-height:1;
	@include fs(16);
  @include fg();
	color: #333;
	-webkit-text-size-adjust: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: #fff;
  @media screen and (max-width:767px) {
    @include fs(14);
  }
}

h1{
  @include fs(42);
  text-align: left;
  margin-bottom: 0;
  color: #fff;
  letter-spacing: 1px;
  span{
    position: absolute;
    display: block;
    @include fs(16);
    text-align: center;
    letter-spacing: 0;
    padding-top: .3em;
  }
  @media screen and (max-width:767px) {
    @include fs(32);
    padding: .8em .2em;
    span{
      @include fs(13);
    }
  }
}

h2{
  margin-bottom: 1.5em;
  font-weight: 500;
  @include fs(36);
  @include fg();
  text-align: center;
  padding-bottom: .8em;
  position: relative;
  letter-spacing: 2px;
  color: #0a2b60;
  span{
    padding-bottom: .4em;
    display: block;
    @include fs(18);
    font-weight: 400;
    letter-spacing: 0;
  }
  &::after{
    display: block;
    position: absolute;
    content: '';
    width: 2.5em;
    height: 5px;
    background-color: #0a2b60;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto auto 0;
  }
  @media screen and (max-width:768px) {
    font-size: 7vw;
    span{
      @include fs(16);
    }
  }
}

h3{
  @include fs(28);
  padding: 0 0 .3em .3em;
  border-bottom: $color_primary solid 1px;
  margin-bottom: 1em;
  line-height: 1.3em;
  span{
    @include fs(18);
    padding-left: 1em;
  }
  @media screen and (max-width:768px) {
    @include fs(24);
    span{
      @include fs(14);
    }
  }
}

h4{
  @include fs(24);
  color: #0a2b60;
  margin-bottom: .5em;
  @media screen and (max-width:768px) {
    font-size: 5.2vw;
  }
}

section{
  padding: 3% 0;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  .s_hdr{
    display: flex;
    width: 100%;
    align-items: center;
  }
	.inner{
		overflow: hidden;
		width: 100%;
		max-width: 1144px;
		margin: 0 auto 5%;
    padding: 0 12px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  &:last-of-type{
    margin-bottom: 0;
    padding-bottom: 5%;
  }
  @media screen and (max-width:1024px) {
    
  }
  @media screen and (max-width:767px) {
    padding: 10% 0;
    .inner{
      padding: 0 3%;
    }
  }
}

figure{
  width: 100%;
  margin: 0 auto 2em;
  text-align: center;
  line-height: 0;
  img{
    max-width: 100%;
    margin: 0 auto;
    line-height: 1;
  }
  figcaption{
    padding-top: .5em;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

p{
  margin-bottom: 1.5em;
  line-height: 2em;
  @include fs(18);
  font-weight: 500;
  &:last-child{
    margin-bottom: 0;
  }
  @media screen and (max-width:768px) {
    /*font-size: 3.7vw; 14px*/
    font-size: 4.2vw; /*16px*/
    /*font-size: 4.8vw; 18px*/
    /*font-size: 5.3vw; 20px*/
  }
}

a{
  color: #333;
  text-decoration: none;
}

article{
  padding: 3%;
  margin-bottom: 5%;;
  .date{
    text-align: right;
  }
  h3{
    margin-bottom: 0;
    a{
      text-decoration: none;
    }
  }
  &:nth-child(odd){
    background-color: rgba($color_primary, .5);
    color: #fff;
    h3{
      border-color: #fff;
    }
  }
}

.lnk_tel{
  text-decoration: none;
  @media (min-width: 768px) {
    pointer-events: none;
    cursor: default;
  }
}

.box_video{
	position: relative;
	width: 100%;
	height: 56.25%;
	padding: 56.25% 0 0 0;
	text-align: center;
	.video_wrapper{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    margin: 0 auto;
		iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
		}
	}
}

.ripple{
	position: relative;
	display: block;
	img{
		max-width: 100%;
		margin: 0 auto;
	}
	span{
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 90%;
		height: 100%;
		margin: auto;
		animation-name: rippleAnimation;
		animation-duration: 2s;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
		border-radius: 100px;
		background-color: #fff072;
		box-shadow: 0 0 5px white;
	}
}
@-webkit-keyframes rippleAnimation {
  0% {
    transform: scale(1, 1);
    opacity: .5;
  }

  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
@keyframes rippleAnimation {
  0% {
    transform: scale(1, 1);
    opacity: .5;
  }

  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

.ripple2{
  position: relative;
  display: block;
	img{
    max-width: 100%;
		margin: 0 auto;
	}
	span{
		&.clr_1, &.clr_2{
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 70px;
      height: 70px;
      margin: auto;
      -webkit-animation-name: rippleAnimation2;
      animation-name: rippleAnimation2;
      -webkit-animation-duration: 2s;
      animation-duration: 2s;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 100%;
      box-shadow: 0 0 45px 1px #ff004a inset;
		}
		&.clr_2{
      -webkit-animation-delay: .3s;
      animation-delay: .3s;
		}
	}
}
@-webkit-keyframes rippleAnimation2{
  0% {
      transform: scale(1,1);
      opacity: 1;
  }
  50% {
      opacity: 0.6;
  }
  80% {
      transform: scale(4.5,4.5);
      opacity: 0;
  }
  100% {
      transform: scale(4.5,4.5);
      opacity: 0;
  }
}
@keyframes rippleAnimation2{
  0% {
      transform: scale(1,1);
      opacity: 1;
  }
  50% {
      opacity: 0.6;
  }
  80% {
      transform: scale(4.5,4.5);
      opacity: 0;
  }
  100% {
      transform: scale(4.5,4.5);
      opacity: 0;
  }
}

.bounce{
	position: relative;
	&::after{
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 100px;
		height: 50px;
		margin: 0 auto auto;
		content: '';
		background: url(../img/down.png) no-repeat center top;
		animation-name: bounce;
		animation-duration: 2s;
		animation-timing-function: ease-out;
		animation-iteration-count: infinite;
	}
}

.pagination{
  margin:40px 0 0;
  .page-numbers{
    display:inline-block;
    margin-right:20px;
    padding:20px 25px;
    color:#333;
    border-radius:3px;
    box-shadow:0 3px 3px #999;
    background:#fff;
  }
  .current{
    padding:20px 25px;
    background:#69a4db;
    color:#fff;
  }
  .prev, .next{
    background:transparent;
    box-shadow:none;
    color:#69a4db;
  }
  .dots{
    background:transparent;
    box-shadow:none;
  }
}
.nav-links{
  display:flex;
  padding: 5px 0;
}

.breadcrumbs {
  width: 100%;
  max-width: 1000px;
  padding: 1%;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 3% 5%;
  }
}

.Fade-In {
  opacity: 0;
  transform: translateY(20px);
  transition: all 1s;
  &.Fade-In-2 {
    transition-delay: 0.2s;
  }
  &.Fade-In-3 {
    transition-delay: 0.3s;
  }
  &.Fade-In-4 {
    transition-delay: 0.4s;
  }
}

.Fade-In-Down {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 1s;
  &.Fade-In-Down-2 {
    transition-delay: 0.2s;
  }
  &.Fade-In-Down-3 {
    transition-delay: 0.3s;
  }
  &.Fade-In-Down-4 {
    transition-delay: 0.4s;
  }
}

.Fade-In-Left {
  opacity: 0;
  transform: translateX(-20px);
  transition: all 1s;
  &.Fade-In-Left-2 {
    transition-delay: 0.2s;
  }
  &.Fade-In-Left-3 {
    transition-delay: 0.3s;
  }
  &.Fade-In-Left-4 {
    transition-delay: 0.4s;
  }
}

.Fade-In-Right {
  opacity: 0;
  transform: translateX(20px);
  transition: all 1s;
  &.Fade-In-Right-2 {
    transition-delay: 0.2s;
  }
  &.Fade-In-Right-3 {
    transition-delay: 0.3s;
  }
  &.Fade-In-Right-4 {
    transition-delay: 0.4s;
  }
}

.t_1{color: #e00;}
.t_2{color: #c00;}
.t_3{color: #c00;}
.t_4{color: #c00;}
.t_5{color: #c00;}
.t_1_b{padding: .1em; color: #c00; background-color: #ff0;}
.t_2_b{padding: .1em; color: #c00; background-color: #ff0;}
.t_3_b{padding: .1em; color: #c00; background-color: #ff0;}
.t_4_b{padding: .1em; color: #c00; background-color: #ff0;}
.t_5_b{padding: .1em; color: #c00; background-color: #ff0;}
.t_lrg{font-size: 130%;}
.t_xlrg{font-size: 170%;}
.t_stg{font-weight: bold;}
.t_ul{text-decoration: underline;}

.t_c{text-align: center;}
.t_r{text-align: right;}
.t_l{text-align: left;}

.f_l{float: left; margin-right: 1em; width: auto;}
.f_r{float: right; margin-left: 1em; width: auto;}

.mb0  {margin-bottom:	0px !important ;}
.mb10 {margin-bottom:	10px !important ;}
.mb20 {margin-bottom:	20px !important ;}
.mb30 {margin-bottom:	30px !important ;}
.mb40 {margin-bottom:	40px !important ;}
.mb50 {margin-bottom:	50px !important ;}
.mb60 {margin-bottom:	60px !important ;}
.mb70 {margin-bottom:	70px !important ;}
.mb80 {margin-bottom:	80px !important ;}
.mb90 {margin-bottom:	90px !important ;}
.mb100{margin-bottom:	100px !important ;}
.mb110{margin-bottom:	110px !important ;}
.mb120{margin-bottom:	120px !important ;}
.mb130{margin-bottom:	130px !important ;}
.mb140{margin-bottom:	140px !important ;}
.mb150{margin-bottom:	150px !important ;}
.mb160{margin-bottom:	160px !important ;}
.mb170{margin-bottom:	170px !important ;}
.mb180{margin-bottom:	180px !important ;}
.mb190{margin-bottom:	190px !important ;}
.mb200{margin-bottom:	200px !important ;}
.mb210{margin-bottom:	210px !important ;}
.mb220{margin-bottom:	220px !important ;}
.mb230{margin-bottom:	230px !important ;}
.mb240{margin-bottom:	240px !important ;}
.mb250{margin-bottom:	250px !important ;}
.mb260{margin-bottom:	260px !important ;}
.mb270{margin-bottom:	270px !important ;}
.mb280{margin-bottom:	280px !important ;}
.mb290{margin-bottom:	290px !important ;}
.mb300{margin-bottom:	300px !important ;}

.pb0{padding-bottom: 0px !important ;}
.pb10{padding-bottom: 10px !important ;}
.pb20{padding-bottom: 20px !important ;}
.pb30{padding-bottom: 30px !important ;}
.pb40{padding-bottom: 40px !important ;}
.pb50{padding-bottom: 50px !important ;}
.pb60{padding-bottom: 60px !important ;}
.pb70{padding-bottom: 70px !important ;}
.pb80{padding-bottom: 80px !important ;}
.pb90{padding-bottom: 90px !important ;}
.pb100{padding-bottom: 100px !important ;}
.pb110{padding-bottom: 110px !important ;}
.pb120{padding-bottom: 120px !important ;}
.pb130{padding-bottom: 130px !important ;}
.pb140{padding-bottom: 140px !important ;}
.pb150{padding-bottom: 150px !important ;}
.pb160{padding-bottom: 160px !important ;}
.pb170{padding-bottom: 170px !important ;}
.pb180{padding-bottom: 180px !important ;}
.pb190{padding-bottom: 190px !important ;}
.pb200{padding-bottom: 200px !important ;}
.pb210{padding-bottom: 210px !important ;}
.pb220{padding-bottom: 220px !important ;}
.pb230{padding-bottom: 230px !important ;}
.pb240{padding-bottom: 240px !important ;}
.pb250{padding-bottom: 250px !important ;}
.pb260{padding-bottom: 260px !important ;}
.pb270{padding-bottom: 270px !important ;}
.pb280{padding-bottom: 280px !important ;}
.pb290{padding-bottom: 290px !important ;}
.pb300{padding-bottom: 300px !important ;}
