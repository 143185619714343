.more{
  a{
    display: block;
    width: 100%;
    max-width: 320px;
    color: #fff;
    background: $color_primary;
    text-decoration: none;
    text-align: center;
    @include fs(20);
    letter-spacing: 2px;
    padding: .5em;
    transition-duration: .3s;
    &.arw{
      position: relative;
      padding: .7em 1em;
      &:before, &:after{
        position: absolute;
        display: block;
        content: '';
        transition-duration: .3s;
        top: 0;
        bottom: 0;
        left: 0;
      }
      &:before{
        transform: rotate(-45deg);
        width: 1px;
        height: 1em;
        right: 6px;
        background-color: #fff;
        margin: auto 7% 23px auto;
      }
      &:after{
        width: 35px;
        height: 1px;
        right: 0;
        background-color: #fff;
        margin: auto 7% 26px auto;
      }
    }
    &:hover{
      background-color: #333;
      &.arw{
        &:before{
          right: -1%;
        }
        &:after{
          right: -3%;
        }
      }
    }
    &.inv{
      background-color: #fff;
      color: $color_primary;
      &:before, &:after{
        background-color: $color_primary;
      }
    }
  }
  @media screen and (max-width:768px) {
    a.arw{
      font-size: 4.2vw;
      &:before{
        height: .8em;
        margin: auto 6% 25px auto;
      }
      &:after{
        width: 25px;
      }
      &.inv{
        background-color: #fff;
        color: $color_primary;
        &:before, &:after{
          background-color: $color_primary;
        }
      }
    }
  }
}

.tbl_1{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  tr{
    th, td{
      padding: .5em;
      @include fs(16);
      line-height: 1.35em;
    }
    th{
      width: 30%;
      background-color: rgba($color_primary, .7);
      vertical-align: middle;
      color: #fff;
      border-right: #fff solid 1px;
      border-bottom: #fff solid 1px;
    }
    td{
      background-color: rgba($color_primary, .05);
      text-align: center;
      border-bottom: rgba($color_primary, .7) solid 1px;
    }
  }
  @media screen and (max-width:767px) {
    tr{
      th, td{
        @include fs(13);
      }
      th{
        width: 30%;
      }
      td{
        width: 70%;
        word-break: break-all;
      }
    }
  }
}

dl{
  &.faq{
    line-height: 1.65em;
    dt, dd{
      line-height: 1.4em;
    }
    dt{
      background-color: rgba($color_primary, .7);
      padding: .8em 3em .8em .8em;
      @include fs(18);
      color: #fff;
      margin-bottom: 1em;
      position: relative;
      &::before, &::after{
        position: absolute;
        display: block;
        content: '';
        background-color: #fff;
        width: 1.5em;
        height: 3px;
        top: 0;
        right: 20px;
        bottom: 0;
        left: 0;
        margin: auto 0 auto auto;
        transition-duration: .5s;
      }
      &::after{
        transform: rotate(90deg);
      }
      &.active{
        &::after{
          transform: rotate(0);
        }
      }
    }
    dd{
      display: none;
      margin-top: -1em;
      margin-bottom: 2em;
      padding: 1.2em;
      background-color: rgba($color_primary, .05);
      border-radius: 0 0 10px 10px;
      &.active{
        display: block;
      }
    }
    @media screen and (max-width:767px) {
      dt{
        &::before, &::after{
          right: 10px;
        }
      }
    }
  }
}

.hl_box{
  padding: 1.2em 1.5em;
  margin-bottom: 2em;
  overflow: hidden;
  position: relative;
  &:nth-child(odd), &.ext{
    background-color: rgba($color_primary, .05);
  }
  &.ext{
    background-color: #fff;
  }
  &::before, &::after{
    position: absolute;
    display: block;
    content: '';
    width: 1em;
    height: 1em;
    background-color: $color_primary;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: rotate(45deg);
  }
  &::before{
    margin: -.5em auto auto -.5em;
  }
  &::after{
    margin: auto -.5em -.5em auto;
  }
}

.map_wide {
  position: relative;
  min-height: 390px;
  #map_canvas, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  img {
    max-width: none;
  }
}

.w2{width: 20%;}
.w25{width: 25%;}
.w3{width: 30%;}
.w35{width: 35%;}
.w4{width: 40%;}
.w45{width: 45%;}
.w5{width: 50%;}
.w55{width: 55%;}
.w6{width: 60%;}
.w65{width: 65%;}
.w7{width: 70%;}
.w75{width: 75%;}
.w8{width: 80%;}
.w85{width: 85%;}
.w9{width: 90%;}
.w95{width: 95%;}
.w33{width: 33%;}

.f_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.gtr{
    .w2{width: 18%;}
    .w3{width: 28%;}
    .w4{width: 38%;}
    .w5{width: 48%;}
    .w6{width: 58%;}
    .w7{width: 68%;}
    .w8{width: 78%;}
    .w9{width: 88%;}
    .w25{width: 23%;}
    .w35{width: 33%;}
    .w45{width: 43%;}
    .w55{width: 53%;}
    .w65{width: 63%;}
    .w75{width: 73%;}
    .w85{width: 83%;}
    .w95{width: 93%;}
    .w33{width: 31%;}
  }
  &.flw{
    flex-flow: wrap;
  }
  &.r_rvs{
    flex-flow: row-reverse;
  }
  &.w_rvs{
    flex-flow: wrap-reverse;
  }
  @media screen and (max-width:767px) {
    flex-flow: column;
    &.gtr{
      .w2, .w3, .w4, .w5, .w6, .w7, .w8, .w9, .w25, .w35, .w45, .w55, .w65, .w75, .w85, .w95, .w33{
        width: 100%; margin-bottom: 30px;
        &.sp_2{
          flex-flow: wrap;
          .w2, .w3, .w4, .w5, .w6, .w7, .w8, .w9, .w25, .w35, .w45, .w55, .w65, .w75, .w85, .w95, .w33{width: 48%; margin-bottom: 15px;}
        }
      }
    }
    &.flw{
      flex-flow: wrap;
    }
    &.r_rvs{
      flex-flow: column;
    }
    &.w_rvs{
      flex-flow: column;
    }
    &.sp_2{
      flex-flow: wrap;
      .w2, .w3, .w4, .w5, .w6, .w7, .w8, .w9, .w25, .w35, .w45, .w55, .w65, .w75, .w85, .w95, .w33{
        width: 50%; margin-bottom: 15px;
      }
    }
  }
}